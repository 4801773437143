/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ACTION_TYPES, getPriceStr } from "./utils"
import { useLocation } from "@reach/router"

const SeoResults = ({ description, lang, meta, title, searchParams, location }) => {

  const {areas, bedrooms, pType, type, price, commType } = searchParams;
  const areaname = searchParams.areas.replace("-", ' ').replace('manchester','Manchetser');
  const pricerange = searchParams.price
  const minprice = searchParams.price.min
  const maxprice = searchParams.price.max
  const propType = searchParams.type
  const bedRooms = searchParams.bedrooms

  const actionStr = ACTION_TYPES[pType];
  const priceStr = getPriceStr(price)

  let propertyType = ""

  if(type == "") {
    propertyType = "properties"
  } else {
    propertyType = type
  }
  
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  let desc = "";
  desc="Find property "+actionStr+" "+capitalize(areaname)+". Explore the wide range of properties "+actionStr+" london with Orlando Reid, specialist "+capitalize(areaname)+" estate agents."  
  if(pricerange) {
    desc="Search "+propertyType+" "+actionStr+" "+capitalize(areaname)+" with Orlando Reid, book a viewing for interested property or visit our office directly and get more information."
  }
  if(propType){
    desc="View wide range of "+propertyType+" "+actionStr+" "+capitalize(areaname)+" with Orlando Reid, Get assisted for all your property needs."
  }
  if(bedRooms) {
    desc="Here is the finest collection of "+bedRooms+" bedroom property "+actionStr+" "+capitalize(areaname)+" with Orlando Reid, leading estate agents in London."
  }
  if(pricerange && propType) {
    desc = "Discover "+propertyType+" "+actionStr+" "+capitalize(areaname)+" with Orlando Reid, the leading estate and letting agents in the "+capitalize(areaname)+"."
  }
  if(pricerange && bedRooms) {
    desc = "Looking for "+bedRooms+" bedroom property "+actionStr+" "+capitalize(areaname)+"? Contact Orlando Reid today for all your property needs."
  }
  if(propType && bedRooms) {
    desc = "View "+bedRooms+" bedroom "+propertyType+" "+actionStr+" "+capitalize(areaname)+", Contact Orlando Reid leading estate and letting agents."
  }
  if(minprice && propType && bedRooms) {
    desc = "Find "+bedRooms+" bedroom "+propertyType+" "+actionStr+" "+capitalize(areaname)+" "+priceStr+" with Orlando Reid, our experts help you find the perfect "+propertyType+" in "+capitalize(areaname)+"."
  }
  if(maxprice && propType && bedRooms) {
    desc = "Find "+bedRooms+" bedroom "+propertyType+" "+actionStr+" "+capitalize(areaname)+" "+priceStr+" with Orlando Reid, our experts help you find the perfect "+propertyType+" in "+capitalize(areaname)+"."
  }
    //
    let titlePropertyType = ""

    if(type == "") {
        titlePropertyType = "Properties"
    } else if(type == "apartments") {
        titlePropertyType = "Apartments"
    } else if(type == "houses") {
        titlePropertyType = "Houses"
    } else if(type == "maisonette") {
        titlePropertyType = "Maisonette"
    } else if(type == "property") {
        titlePropertyType = "Property"
    } else if(type == "commercial") {
        titlePropertyType = "Commercial"
    } else {
        titlePropertyType = type
    }

    let bedroomType = ""

    if ( bedrooms ) {
      bedroomType = bedrooms + ' + Bedroom '
    }

    let descTitle = bedroomType + capitalize(titlePropertyType) + actionStr + capitalize(areas) + priceStr
    //

  // console.log("Props Desc => ", desc);
  var { pathname } = useLocation()

  if (pathname == "/property/to-rent/" || pathname == "/property/to-rent" )
    pathname = "/property/to-rent/in-london-and-manchester"

  if (pathname == "/property/for-sale/" || pathname == "/property/for-sale" )
    pathname = "/property/for-sale/in-london-and-manchester"

  if (pathname == "/property/new-homes/for-sale/" || pathname == "/property/new-homes/for-sale" )
    pathname = "/property/new-homes/for-sale/in-london-and-manchester"

  if (pathname == "/property/new-homes/to-rent/" || pathname == "/property/new-homes/to-rent" )
    pathname = "/property/new-homes/to-rent/in-london-and-manchester"

  if (pathname == "/property/help-to-buy/for-sale/" || pathname == "/property/help-to-buy/for-sale" )
    pathname = "property/help-to-buy/for-sale/in-london-and-manchester"

  var robotContent = "all";
  if(pathname === "/property/for-sale/in-london-and-manchester/type-apartments/"){
      robotContent = "noindex";
  }
//console.log(pathname)
  var curentUrl = '';
  curentUrl = process.env.GATSBY_SITE_URL+pathname;

  // set no index for result pagination
  let hasPagination = false;
  if (pathname !== undefined && pathname) {
    let pageStr = pathname.split('page-');
    hasPagination = pageStr[1] || false;
    if (pageStr[0] !== undefined) {
     curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
    }
  }

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
  //set lowercase url
  curentUrl = curentUrl.toLowerCase();

  if (!curentUrl.endsWith('/'))
      curentUrl = curentUrl + '/';

  description = desc

  let metaItems = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: descTitle,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
        property: `og:url`,
        content: curentUrl,
    },
    {
        name: `robots`,
        content: robotContent,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: `Orlando Reid`,
    },
    {
      name: `twitter:title`,
      content: descTitle,
    },
    {
      name: `twitter:description`,
      content: description,
    },
  ].concat(meta);


  if ( hasPagination ) {
    // metaItems.push({
    //   name: `robots`,
    //   content: `noindex`,
    // })
  }




  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
          {
              href: curentUrl,
              rel: "canonical"
          }
      ]}
      title={descTitle}
      titleTemplate={`%s | Orlando Reid`}
      meta={metaItems}
    />
  )
}

SeoResults.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  searchParams: [],
}

SeoResults.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  searchParams: PropTypes.any,
}

export default SeoResults
